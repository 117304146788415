var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "ModalComponent",
        {
          attrs: { title: "Create new role", "backdrop-dismiss": "" },
          on: { "close-modal": _vm.closeModal },
        },
        [
          _c("template", { slot: "body" }, [
            _c(
              "div",
              { staticClass: "RoleFormComponent px-3 py-5" },
              [
                _c("MuiInputText", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        isRequired: true,
                      },
                      expression: "{\n          isRequired: true,\n        }",
                    },
                  ],
                  staticClass: "w-100 mb-3",
                  attrs: { label: "Name", name: "displayName" },
                  model: {
                    value: _vm.inputs.displayName,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "displayName", $$v)
                    },
                    expression: "inputs.displayName",
                  },
                }),
                _c("ui-text-area", {
                  attrs: {
                    value: _vm.inputs.description,
                    label: "Description",
                    placeholder: "Reason for creating the unavailability",
                    name: "description",
                  },
                  on: {
                    changevalue: ({ detail }) =>
                      (_vm.inputs.description = detail),
                  },
                }),
              ],
              1
            ),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-content-center justify-content-end w-100",
              },
              [
                _c("CancelButton", {
                  staticClass: "mr-2",
                  attrs: { disabled: _vm.isLoading },
                  on: { click: _vm.closeModal },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      disabled: !_vm.areFieldsValid || _vm.isLoading,
                      loading: _vm.isLoading,
                    },
                    on: { clickbutton: _vm.createRole },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Common.Actions.create")) + " ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }