<script>
import { mapMutations } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { navigationErrorHandler } from '@emobg/web-utils';

import CRMRouterMap from '../../../../router/CRMRouterMap';
import { scopes } from '../../../store/RolesModule';
export default {
  name: 'LinkToRoleComponent',
  props: {
    role: {
      required: true,
      type: Object,
    },
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.crm.roles, {
      setRolesData: 'setData',
    }),
    saveAndRedirectToRole() {
      this.setRolesData({
        scope: scopes.selectedRole,
        value: this.role,
      });
      this.$router.push({
        name: CRMRouterMap.roles.detail,
        params: { roleName: this.role.name },
      }).catch(navigationErrorHandler);
    },
  },
};
</script>
<template>
  <a
    :href="`/user-company/roles/${role.name}`"
    class="emobg-link-primary emobg-body-2"
    @click.prevent="saveAndRedirectToRole"
  >
    {{ role.display_name }}
  </a>
</template>
