<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import RoleFormComponent from './components/Form/RoleFormComponent';

import {
  attributesToRetrieve,
  contentCells,
} from './config/AlgoliaRolesConfig';

export default {
  name: 'RolesListView',
  components: {
    MuiAlgoliaList,
    RoleFormComponent,
    PageView,
  },
  data() {
    return {
      isRoleFormOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      isCarRental: state => get(state, 'operators.active.is_carrental'),
    }),
    roleGropupFilters() {
      return this.isCarRental ? '' : 'group:frontoffice';
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.attributesToRetrieve = attributesToRetrieve;
    this.contentCells = contentCells;
    this.actions = [
      { label: 'Create role', class: 'emobg-color-primary', method: () => { this.isRoleFormOpen = true; } },
    ];
  },
};
</script>

<template>
  <PageView class="RolesListView">
    <h1 class="mb-3">
      Roles
    </h1>

    <MuiAlgoliaList
      :export-columns="contentCells"
      :table-config="contentCells"
      :actions="actions"
      :query-parameters="{ attributesToRetrieve }"
      :filters="roleGropupFilters"
      :index="ALGOLIA_INDEXES.roles"
      is-export-enabled
    />

    <RoleFormComponent
      :is-open="isRoleFormOpen"
      :on-close="() => isRoleFormOpen = false"
    />
  </PageView>
</template>
