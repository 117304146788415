var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "emobg-link-primary emobg-body-2",
      attrs: { href: `/user-company/roles/${_vm.role.name}` },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.saveAndRedirectToRole.apply(null, arguments)
        },
      },
    },
    [_vm._v(" " + _vm._s(_vm.role.display_name) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }