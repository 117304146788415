import { RECORD_TYPES } from '@emobg/motion-ui';
import LinkToRoleComponent from '../components/LinkToRole/LinkToRoleComponent';

export const attributesToRetrieve = [
  'id',
  'uuid',
  'display_name',
  'name',
  'group',
  'description',
];

export const contentCells = [
  {
    title: 'Name',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 180,
    type: RECORD_TYPES.component,
    component: LinkToRoleComponent,
    props: result => ({
      role: result,
    }),
  },
  {
    attributeName: 'description',
    title: 'Description',
    displayPriority: 1,
    minWidth: 100,
  },
];
