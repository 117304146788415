var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "RoleDetailView" },
    [
      _c("h2", { staticClass: "mb-3" }, [
        _vm._v(" Role: "),
        _vm.roleName
          ? _c("span", [_vm._v(_vm._s(_vm.sentenceCase(_vm.roleName)))])
          : _vm._e(),
      ]),
      _vm.roleStatus.LOADING
        ? _c("ui-loader", { attrs: { absolute: "" } })
        : _vm._e(),
      _vm.role && _vm.permissions && _vm.permissionsByRole
        ? _c("ui-card", [
            _c(
              "div",
              { staticClass: "row align-items-center" },
              [
                _vm._l(_vm.selectedPermissions, function (permission, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "col-6 col-md-4 col-lg-3 mb-2" },
                    [
                      _c("ui-toggle", {
                        attrs: {
                          value: permission.checked,
                          name: permission.name,
                          text: permission.label,
                        },
                        on: {
                          changevalue: (event) =>
                            (permission.checked = event.detail),
                        },
                      }),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "col-12 mt-5 text-right" },
                  [
                    _c(
                      "ui-button",
                      {
                        attrs: {
                          disabled: !_vm.isPermissionsChanged,
                          loading: _vm.isLoading,
                        },
                        on: { clickbutton: _vm.saveRole },
                      },
                      [_vm._v(" Save ")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.isError
        ? _c("p", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.error) + " ")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }