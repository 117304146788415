var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "RolesListView" },
    [
      _c("h1", { staticClass: "mb-3" }, [_vm._v(" Roles ")]),
      _c("MuiAlgoliaList", {
        attrs: {
          "export-columns": _vm.contentCells,
          "table-config": _vm.contentCells,
          actions: _vm.actions,
          "query-parameters": {
            attributesToRetrieve: _vm.attributesToRetrieve,
          },
          filters: _vm.roleGropupFilters,
          index: _vm.ALGOLIA_INDEXES.roles,
          "is-export-enabled": "",
        },
      }),
      _c("RoleFormComponent", {
        attrs: {
          "is-open": _vm.isRoleFormOpen,
          "on-close": () => (_vm.isRoleFormOpen = false),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }