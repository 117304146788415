<script>
import { mapActions, mapState } from 'vuex';
import { MuiInputText, Validate } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, ModalComponent } from '@/components';

export default {
  name: 'RoleFormComponent',
  components: {
    ModalComponent,
    CancelButton,
    MuiInputText,
  },
  directives: {
    Validate,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      inputs: {
        displayName: '',
        description: '',
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.roles, {
      role: state => state.data,
      roleStatus: state => state.STATUS,
      roleError: state => state.error,
    }),
    areFieldsValid() {
      return this.inputs.displayName !== '';
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.roles, [
      'postRole',
    ]),
    async createRole() {
      this.isLoading = true;
      await this.postRole(this.inputs);

      if (this.roleStatus.LOADED) {
        window.location.href = `/user-company/roles/${this.role.name}`;
      } else if (this.roleStatus.ERROR) {
        this.isLoading = false;
        this.$throwError(this.roleError);
      }
    },
    closeModal() {
      this.inputs.displayName = '';
      this.inputs.description = '';
      this.onClose();
    },
  },
};
</script>

<template>
  <ModalComponent
    v-if="isOpen"
    title="Create new role"
    backdrop-dismiss
    @close-modal="closeModal"
  >
    <template slot="body">
      <div class="RoleFormComponent px-3 py-5">
        <MuiInputText
          v-model="inputs.displayName"
          v-validate="{
            isRequired: true,
          }"
          label="Name"
          name="displayName"
          class="w-100 mb-3"
        />
        <ui-text-area
          :value="inputs.description"
          label="Description"
          placeholder="Reason for creating the unavailability"
          name="description"
          @changevalue="({ detail }) => inputs.description = detail"
        />
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex align-content-center justify-content-end w-100">
        <CancelButton
          :disabled="isLoading"
          class="mr-2"
          @click="closeModal"
        />

        <ui-button
          :disabled="!areFieldsValid || isLoading"
          :loading="isLoading"
          @clickbutton="createRole"
        >
          {{ $t('Common.Actions.create') }}
        </ui-button>
      </div>
    </template>
  </ModalComponent>
</template>
