<script>
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import first from 'lodash/first';
import indexOf from 'lodash/indexOf';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import { mapActions, mapMutations, mapState } from 'vuex';
import { navigationErrorHandler, sentenceCase } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import { NOT_FOUND } from '@/constants/urls';

import { scopes as permissionScopes } from '@/store/modules/App/Permissions/PermissionsModule';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { scopes as roleScopes } from '../store/RolesModule';

export default {
  name: 'RoleDetailsView',
  components: {
    PageView,
  },
  props: {
    roleName: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selectedPermissions: [],
      isLoading: false,
      isError: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.permissions, {
      permissions: state => state.permissions.data,
      permissionsByRole: state => state.permissionsByRole.data,
    }),
    ...mapState(DOMAINS_MODEL.crm.roles, {
      role: state => state.selectedRole.data,
      roleResponse: state => state.data,
      roleStatus: state => state.STATUS,
      roleError: state => state.error,
    }),
    permissionsToSend() {
      const checkedPermissions = filter(this.selectedPermissions, { checked: true });
      return map(checkedPermissions, 'name');
    },
    isPermissionsChanged() {
      const diffInPermissionsByRole = difference(this.permissionsByRole, this.permissionsToSend);
      const diffInPermissionsToSend = difference(this.permissionsToSend, this.permissionsByRole);

      return diffInPermissionsByRole.length || diffInPermissionsToSend.length;
    },
  },
  async created() {
    if (isNil(this.role)) {
      try {
        const roleResponse = await this.$algolia.fetchIndex('roles', {
          filters: `name:${this.roleName}`,
        });
        const roleFromAlgolia = first(roleResponse.hits);
        if (roleFromAlgolia) {
          this.setRole({
            scope: roleScopes.selectedRole,
            value: roleFromAlgolia,
          });
        } else {
          this.$router.push(NOT_FOUND).catch(navigationErrorHandler);

          return;
        }
      } catch (error) {
        this.$notify({
          message: 'Error fetching the role',
          textAction: 'OK',
          type: NOTIFICATION_TYPES.error,
        });
        this.isError = true;

        return;
      }
    }
    await this.getPermissions();
    await this.getPermissionsByRole(this.roleName);
    this.selectedPermissions = map(this.permissions, permission => ({
      name: permission,
      label: sentenceCase(permission),
      checked: indexOf(this.permissionsByRole, permission) > -1,
    }));
  },
  destroyed() {
    this.setRole({
      scope: roleScopes.selectedRole,
      value: null,
    });
  },
  methods: {
    sentenceCase,
    ...mapActions(DOMAINS_MODEL.app.permissions, [
      'getPermissions',
      'getPermissionsByRole',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.permissions, {
      setPermissionsByRole: 'setData',
    }),
    ...mapActions(DOMAINS_MODEL.crm.roles, [
      'putRole',
    ]),
    ...mapMutations(DOMAINS_MODEL.crm.roles, {
      setRole: 'setData',
    }),
    async saveRole() {
      this.isLoading = true;
      await this.putRole({
        roleName: this.roleName,
        data: {
          permissions: this.permissionsToSend,
        },
      });

      if (this.roleStatus.ERROR) {
        this.isLoading = false;
        this.$throwError(this.roleError);

        return;
      }

      this.setPermissionsByRole({
        scope: permissionScopes.permissionsByRole,
        value: this.roleResponse,
      });

      this.$notify({
        message: 'Role permissions saved correctly',
        textAction: 'OK',
      });
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <PageView class="RoleDetailView">
    <h2 class="mb-3">
      Role: <span v-if="roleName">{{ sentenceCase(roleName) }}</span>
    </h2>
    <ui-loader
      v-if="roleStatus.LOADING"
      absolute
    />
    <ui-card v-if="role && permissions && permissionsByRole">
      <div class="row align-items-center">
        <div
          v-for="(permission, index) in selectedPermissions"
          :key="index"
          class="col-6 col-md-4 col-lg-3 mb-2"
        >
          <ui-toggle
            :value="permission.checked"
            :name="permission.name"
            :text="permission.label"
            @changevalue="(event) => permission.checked = event.detail"
          />
        </div>
        <div class="col-12 mt-5 text-right">
          <ui-button
            :disabled="!isPermissionsChanged"
            :loading="isLoading"
            @clickbutton="saveRole"
          >
            Save
          </ui-button>
        </div>
      </div>
    </ui-card>
    <p v-if="isError">
      {{ FALLBACK_MESSAGE.error }}
    </p>
  </PageView>
</template>
